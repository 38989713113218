





import {Component, Prop, Vue} from 'vue-property-decorator';

/**
 * @status stable
 * @published $next
 * @changelog $next Published.
 */
@Component
export default class Action extends Vue {
    @Prop({type: Boolean, default: false}) disabled!: boolean

    tap(e: MouseEvent) {
        if (e.shiftKey) {
            this.$emit('tap', 1)
        } else {
            this.$emit('tap', 0.2)
        }
    }
}
