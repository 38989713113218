













import {Component, Prop, Vue} from 'vue-property-decorator'
import {colord} from "colord"

@Component({
    components: {
    },
})
export default class ColorCell extends Vue {
    @Prop() name!: string
    @Prop() color!: string

    get raw() {
        return colord(this.color)
    }
}
