






































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Colord, colord} from "colord"

@Component({
    components: {
    },
})
export default class ColorHueVarianceChart extends Vue {
    @Prop() colors!: Array<string|Colord>

    @Prop({default: 'lch'}) model!: 'lch'|'wcag'

    getBrightnessAxis(color: Colord) {
        switch (this.model) {
            case('lch'): return color.toLch().l / 100
            default: return color.luminance()
        }
    }

    hueModifier = 30 // this means we expect a 30 degree hue shift from average at most

    // if the std deviation is >50, we'll assume the colors are progressing through the 0deg/360deg singularity, and
    // invert all the colors so we can do standard math on them. Just make sure we invert again when displaying them!
    get offsetMode() {
        return ((arr) => {
            let i,j,total = 0, mean = 0, diffSqredArr = [];
            for(i=0;i<arr.length;i+=1){
                total+=arr[i];
            }
            mean = total/arr.length;
            for(j=0;j<arr.length;j+=1){
                diffSqredArr.push(Math.pow((arr[j]-mean),2));
            }
            return (Math.sqrt(diffSqredArr.reduce(function(firstEl, nextEl){
                return firstEl + nextEl;
            })/arr.length));
        })((Object.values(this.colors)
            .map(c => colord(c).toHsl().h))) > 50
    }

    get normalizedColors(): Array<Colord|string> {
        if (this.offsetMode) {
            return Object.values(this.colors).map(c => {
                return colord(c).rotate(180)
            })
        }

        return Object.values(this.colors);

    }

    get deviation() {
        return 0
    }

    get centerHue() {
        let average = ( arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length)
        (this.normalizedColors.map(c => colord(c).toHsl().h))

        if (this.offsetMode) {
            // flip when in offset
            return average - 180
        } else {
            return average
        }

    }

    blobStyles(original: string) {
        const color = colord(original)


        if (this.offsetMode) {
            // normalize for offset mode
            return {
                backgroundColor: color.rotate(-180).toHex(),
                left: 50 + ((( (this.offsetMode ? color.toHsl().h - 180 : color.toHsl().h) - this.centerHue) / this.hueModifier) * 50) + '%',
                top: 92 - (this.getBrightnessAxis(color.rotate(-180)) * 92) + '%',
            }
        } else {
            return {
                backgroundColor: color.toHex(),
                left: 50 + (((color.toHsl().h - this.centerHue) / this.hueModifier) * 50) + '%',
                top: 92 - (this.getBrightnessAxis(color) * 92) + '%',
            }
        }
    }

    get barStyles() {
        const left = colord({h: this.centerHue - this.hueModifier, s: 100, v: 100})
        const middle = colord({h: this.centerHue, s: 100, v: 100})
        const right = colord({h: this.centerHue + this.hueModifier, s: 100, v: 100})

        return {
            background: `linear-gradient(90deg, ${left.toHex()}, ${middle.toHex()}, ${right.toHex()})`
        }
    }
}
