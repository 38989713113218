







import {Component, Prop, Vue} from 'vue-property-decorator'
import {Colord, colord} from "colord"
import MicroBar from "@/components/MicroBar.vue"

@Component({
    components: {
        MicroBar
    },
})
export default class AccessibilityCell extends Vue {
    @Prop() name!: string
    @Prop() color!: string
    @Prop() allColors!: {[key: string]: {[key: string]: string}}
    @Prop({default: 'lch'}) model!: 'lch'|'wcag'


    metric(color: Colord) {
        switch (this.model) {
            case('lch'): return (color.toLch().l / 100)
            default: return color.luminance()
        }
    }

    get median() {
        return((arr) => arr.slice().sort((a, b) => a - b)[Math.floor(arr.length / 2)])
        (Object.values(this.allColors)
            .map(c => c[this.name])
            .filter(c => c != undefined)
            .map(c => this.metric(colord(c))))
    }

    get avg() {

        return ( arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length)
        (Object.values(this.allColors)
            .map(c => c[this.name])
            .filter(c => c != undefined)
            .map(c => this.metric(colord(c))))
    }

    get stdDeviation() {
        return (function(arr: Array<number>) {
        const n = arr.length
        const mean = arr.reduce((a, b) => a + b) / n
        return Math.sqrt(arr.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
        })
        (Object.values(this.allColors)
            .map(c => c[this.name])
            .filter(c => c != undefined)
            .map(c => this.metric(colord(c))))
    }

    get middleFifty() {
        const q = (arr: Array<number>, q: number) => {
            const sorted = arr.sort((a, b) => a - b);
            const pos = (sorted.length - 1) * q
            const base = Math.floor(pos)
            const rest = pos - base
            if (sorted[base + 1] !== undefined) {
                return sorted[base] + rest * (sorted[base + 1] - sorted[base])
            } else {
                return sorted[base]
            }
        }

        const values = Object.values(this.allColors)
            .map(c => c[this.name])
            .filter(c => c != undefined)
            .map(c => this.metric(colord(c)))

        return [q(values, 0.25), q(values, 0.75)]
    }

    get raw() {
        return colord(this.color)
    }
}
