















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {colord, Colord} from "colord"

@Component
export default class GradientSimulator extends Vue {
    @Prop() colors!: Record<string, string>
    start = ''
    end = ''

    get options() {
        return Object.keys(this.colors)
    }

    get styles() {
        return {
            background: `linear-gradient(90deg, ${colord(this.colors[this.start]).toHex()}, ${colord(this.colors[this.end]).toHex()})`
        }
    }

    @Watch('options', {deep: true, immediate: true})
    pickDefaults() {
        this.$nextTick(() => {
            if (!this.options.includes(this.start)) {
                this.start = this.options[0]
            }
            if (!this.options.includes(this.end)) {
                this.end = this.options[this.options.length - 1]
            }
        })
    }

}
