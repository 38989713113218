







































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Colord, colord} from "colord"

@Component({
    components: {
    },
})
export default class ColorLuminanceSaturationChart extends Vue {
    @Prop() colors!: Array<string>
    @Prop({default: 'lch'}) model!: 'lch'|'wcag'

    verticalAxis(color: Colord) {
        switch (this.model) {
            case('lch'): return color.toLch().l / 100
            default: return color.luminance()
        }
    }

    blobStyles(original: string) {
        const color = colord(original)
        return {
            backgroundColor: color.toHex(),
            left: 97 - (this.verticalAxis(color) * 97) + '%',
            top: 100 - color.toHsl().s + '%',
        }
    }

    softBlobStyles(original: string) {
        const color = colord(original)
        return {
            backgroundColor: color.toHex(),
            left: 97 - (this.verticalAxis(color) * 97) + '%',
            top: 100 - (color.toLch().c * 100 / 130) + '%',
        }
    }



    get barStyles() {
        return {
            background: `linear-gradient(180deg, ${Object.values(this.colors)[Math.floor(Object.keys(this.colors).length / 2)]}, black)`
        }
    }
}
