








































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Colord, colord} from "colord"
import MicroBar from "@/components/MicroBar.vue"
import AdjustableBar from "@/components/AdjustableBar.vue"
import Action from "@/components/Action.vue"
import Fieldset from "@/components/Fieldset.vue"

@Component({
    components: {
        Fieldset,
        Action,
        AdjustableBar,
        MicroBar
    },
})
export default class ColorTweaker extends Vue {
    @Prop() name!: string
    @Prop() color!: string
    @Prop() allColors!: {[key: string]: {[key: string]: string}}

    @Prop() value!: string
    @Prop() mode!: 'lch'|'hsl'|'both'

    get raw() {
        return colord(this.value)
    }

    get hueBase() {
        return colord({l: 70, c: 20, h: this.raw.toLch().h}).toHex()
    }

    get satBase() {
        return '#999'
    }

    get lightnessBase() {
        return colord({h: 0, s: 0, l: 10 + (this.raw.toHsl().l * 0.7)}).toHex()
    }

    tweak(color: Colord) {
        this.$emit('input', color)
    }

    changeHslHue(target: number) {
        // short-circuit
        if (this.raw.toHsl().h === target) return

        let newColor = colord({h: target, s: this.raw.toHsl().s, l: this.raw.toHsl().l})
        const currentColor = this.raw.toHex()
        let increment = 0, safety = 0
        while (currentColor === newColor.toHex() && safety < 20) {
            increment += target > this.raw.toHsl().h ? 1 : -1
            safety++
            newColor = colord({h: target + increment, s: this.raw.toHsl().s, l: this.raw.toHsl().l})
        }

        this.$emit('input', newColor.toHex())
    }

    changeSaturation(target: number) {
        // short-circuit
        if (this.raw.toHsl().s === target) return

        let newColor = colord({h: this.raw.toHsl().h, s: target, l: this.raw.toHsl().l})
        const currentColor = this.raw.toHex()
        let increment = 0, safety = 0


        while (currentColor === newColor.toHex() && safety < 40) {
            increment += target > this.raw.toHsl().s ? 1 : -1
            safety++
            newColor = colord({h: this.raw.toHsl().h, s: target + increment, l: this.raw.toHsl().l})
        }

        this.$emit('input', newColor.toHex())
    }

    changeLightness(target: number) {
        // short-circuit
        if (this.raw.toHsl().l === target) return

        let newColor = colord({h: this.raw.toHsl().h, s: this.raw.toHsl().s, l: target})
        const currentColor = this.raw.toHex()
        let increment = 0, safety = 0


        while (currentColor === newColor.toHex() && safety < 40) {
            increment += target > this.raw.toHsl().l ? 1 : -1
            safety++
            newColor = colord({h: this.raw.toHsl().h, s: this.raw.toHsl().s, l: target + increment})
        }

        this.$emit('input', newColor.toHex())
    }

    /**
     * LCH controls
     */

    changeLchHue(rawTarget: number) {
        // short-circuit
        if (this.raw.toLch().h === rawTarget) return

        const target = Math.round(rawTarget)

        let newColor = colord({h: target, c: this.raw.toLch().c, l: this.raw.toLch().l})
        const currentColor = this.raw.toHex()
        let increment = 0, safety = 0
        while (currentColor === newColor.toHex() && safety < 20) {
            increment += target > this.raw.toLch().h ? 1 : -1
            safety++
            newColor = colord({h: target + increment, c: this.raw.toLch().c, l: this.raw.toLch().l})
        }

        this.$emit('input', newColor.toHex())
    }

    changeLchLightness(rawTarget: number) {
        // short-circuit
        if (this.raw.toLch().h === rawTarget) return

        const target = Math.round(rawTarget)

        let newColor = colord({l: target, c: this.raw.toLch().c, h: this.raw.toLch().h})
        const currentColor = this.raw.toHex()
        let increment = 0, safety = 0
        while (currentColor === newColor.toHex() && safety < 20) {
            increment += target > this.raw.toLch().l ? 1 : -1
            safety++
            newColor = colord({l: target + increment, c: this.raw.toLch().c, h: this.raw.toLch().h})
        }

        this.$emit('input', newColor.toHex())
    }

    changeLchChroma(rawTarget: number) {
        // short-circuit
        if (this.raw.toLch().h === rawTarget) return

        const target = Math.round(rawTarget)

        let newColor = colord({c: target, l: this.raw.toLch().l, h: this.raw.toLch().h})
        const currentColor = this.raw.toHex()
        let increment = 0, safety = 0
        while (currentColor === newColor.toHex() && safety < 20) {
            increment += target > this.raw.toLch().c ? 1 : -1
            safety++
            newColor = colord({c: target + increment, l: this.raw.toLch().l, h: this.raw.toLch().h})
        }

        this.$emit('input', newColor.toHex())
    }
}
