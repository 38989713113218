










import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    components: {
    },
})
export default class MicroBar extends Vue {
    @Prop() value!: number
    @Prop() benchmark!: number
    @Prop() benchmark2!: number
    @Prop() range!: [number, number]

}
