
































































































import {Component, Ref, Vue, Watch} from 'vue-property-decorator'
import defaultJson from './default.json'
import tailwindJson from './tw.json'
import materialJson from './material.json'
import helpScoutJson from './helpscout.json'
import jds2Json from './jds.json'
import Swatch from "@/components/Swatch.vue"
import {colord} from "colord"
import {Mode} from "@/mode"

const renameObjKey = (source: Record<string, Record<string, string>>, oldKey: string, newKey: string) => {
    const keys = Object.keys(source)
    return keys.reduce<Record<string, Record<string, string>>>((result, key) => {
        if (key === oldKey) {
            result[newKey] = source[oldKey]
        } else {
            result[key] = source[key]
        }
        return result
    }, {})
}

const cloneObjKey = (source: Record<string, Record<string, string>>, cloneKey: string) => {
    const keys = Object.keys(source)
    return keys.reduce<Record<string, Record<string, string>>>((result, key) => {
        result[key] = source[key]
        if (key === cloneKey) {
            if (/[0-9]+$/.test(key.substr(-1))) {
                result[key.replace(/\d+$/, n => (parseFloat(n) + 1).toString())] = source[key]
            } else {
                result[key +  '2'] = source[key]
            }
        }
        return result
    }, {})
}

@Component({
    components: {
        Swatch
    },
})
export default class App extends Vue {
    json: { [key: string]: { [key: string]: string } } = jds2Json
    showJson = false
    jsonString = ''
    preset = ''
    @Ref() inputEl!: HTMLInputElement

    mode: Mode = {
        greyscale: false,
        accessibility: false,
        sliderMode: 'both',
        vizBrightness: 'lch',
        a11yBrightness: 'wcag'
    }

    loadPreset() {
        switch (this.preset) {
            case('tw'): this.loadTailwind(); break;
            case('material'): this.loadMaterial(); break;
            case('helpscout'): this.loadHelpScout(); break;
            case('jds'): this.loadJDS(); break;
            case('jds2'): this.loadJDS2(); break;
        }
        this.preset = ''
    }

    updateColor(colors: { [key: string]: string }, name: string) {
        this.$set(this.json, name, Object.fromEntries(Object.entries(colors).map(([key, val]) => [key, colord(val).toHex()])))
    }

    changeName([oldName, newName]: [string, string]) {
        this.json = renameObjKey(this.json, oldName, newName)
    }

    clone(color: string) {
        console.log(`Cloning ${color}`)
        this.json = cloneObjKey(this.json, color)
    }

    @Watch('json', {immediate: true, deep: true})
    syncJson() {
        this.jsonString = JSON.stringify(this.json)
    }

    updateJson() {
        this.json = JSON.parse(<string>this.inputEl.value)
    }

    loadTailwind() {
        this.json = tailwindJson
    }

    loadMaterial() {
        this.json = materialJson
    }

    loadHelpScout() {
        this.json = helpScoutJson
    }

    loadJDS() {
        this.json = defaultJson
    }

    loadJDS2() {
        this.json = jds2Json
    }

    get swatches() {
        return this.json
    }
}
