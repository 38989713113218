































import {Component, Prop, Vue} from 'vue-property-decorator'
import {colord} from "colord"

@Component({
    components: {
    },
})
export default class AdjustableBar extends Vue {

    @Prop() value!: number
    @Prop() color!: string

    @Prop({default: 255}) max!: number
    @Prop({default: 0}) min!: number

    @Prop({default: false, type: Boolean}) loop!: boolean

    isDragging = false
    // startY = 0
    barHeight = 120

    startDrag(e: MouseEvent) {
        this.isDragging = true
        // this.startY = e.offsetY
        // document.body.style.cursor = 'ns-resize'
        window.addEventListener('mouseup', this.handleDragEnd)
        this.$emit('input', ((this.barHeight - e.offsetY) / this.barHeight) * (this.max - this.min))
        // window.addEventListener('mousemove', this.handleMovement)
    }

    handleMovement(e: MouseEvent) {
        if (this.isDragging) {
            // you could also put this handler on the window and compare startY to screenY to get an "infinite-drag" bar
            this.$emit('input', ((this.barHeight - e.offsetY) / this.barHeight) * (this.max - this.min))
        }
    }

    handleDragEnd() {
        this.isDragging = false
        // document.body.style.cursor = 'auto'
        window.removeEventListener('mouseup', this.handleDragEnd)
        window.removeEventListener('mousemove', this.handleMovement)
    }

    checkInput (e: KeyboardEvent) {
        if (!e.code.includes('Digit')) e.preventDefault()
    }

    directInput(rawValue: FocusEvent) {
        const value = Number.parseInt((rawValue?.currentTarget as HTMLInputElement).value)
        if (value > this.max) return this.$emit('input', this.max)
        if (value < this.min) return this.$emit('input', this.min)
        this.$emit('input', Math.round(value))
    }

    increment() {
        if (this.value === this.max) {
            if (this.loop) this.$emit('input', this.min)
            return
        }
        this.$emit('input', this.value + 1)
    }

    decrement() {
        if (this.value === this.min) {
            if (this.loop) this.$emit('input', this.max)
            return
        }
        this.$emit('input', this.value - 1)
    }

    get styles() {
        return {
            height: `${this.barHeight}px`,
            borderColor: colord(this.color).alpha(0.3).toRgbString(),
        }
    }

    get innerStyles() {
        return {
            height: `${this.value / (this.max - this.min) * 100}%`,
            background: this.color,
        }
    }
}
