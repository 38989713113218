































































    import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import ColorCell from "@/components/ColorCell.vue"
import AccessibilityCell from "@/components/AccessibilityCell.vue"
import ColorLuminanceSaturationChart from "@/components/ColorLuminanceSaturationChart.vue"
import ColorHueVarianceChart from "@/components/ColorHueVarianceChart.vue"
import SwatchTweaker from "@/components/SwatchTweaker.vue"
    import ColorTweaker from "@/components/ColorTweaker.vue"
    import GradientCell from "@/components/GradientCell.vue"
    import {Mode} from "@/mode"
    import GradientSimulator from "@/components/GradientSimulator.vue"

@Component({
    components: {
        GradientSimulator,
        ColorTweaker,
        SwatchTweaker,
        ColorHueVarianceChart,
        ColorLuminanceSaturationChart,
        AccessibilityCell,
        ColorCell
    },
})
export default class Swatch extends Vue {
    @Prop() allColors!: {[key: string]: {[key: string]: string}}
    @Prop() colors!: {[key:string]: string}
    @Prop() name!: string
    @Prop() mode!: Mode
    collapse = false
    tweak = false
    newName = ''

    @Ref() actionsEl!: HTMLDialogElement
    @Ref() editEl!: HTMLDialogElement

    clone() {
        this.$emit('clone', this.name)
    }

    openActions() {
        this.actionsEl.showModal()
    }

    openEdits() {
        this.editEl.showModal()
        this.newName = this.name
    }

    saveEditor() {
        if (this.newName !== this.name) {
            this.$emit('rename', [this.name, this.newName])
        }
    }

    emitColors(colors: {[key:string]: string}) {
        this.$emit('input', colors)
    }

    emitColorUpdate(name: string, color: string) {
        const clone = Object.keys(this.colors).reduce<Record<string, string>>((result, cur) => {
            if (cur === name) {
                result[cur] = color
            } else {
                result[cur] = this.colors[cur]
            }
            return result
        }, {})
        this.$emit('input', clone)
    }

    get enumeratedColors () {
        return Object.values(this.allColors).map(c => Object.keys(c)).reduce(function (flat, toFlatten) {
            return flat.concat(toFlatten);
        }, []).filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    }

}
