import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import './assets/tailwind.css'

import { colord, extend } from "colord";
import a11yPlugin from "colord/plugins/a11y";
import lchPlugin from "colord/plugins/lch";

extend([a11yPlugin, lchPlugin]);

new Vue({
  render: h => h(App),
}).$mount('#app')
