


















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Colord, colord} from "colord"
import Action from "@/components/Action.vue"
import Fieldset from "@/components/Fieldset.vue"

function adjust(base: Colord, target: number) {
    let c = colord(base)
    console.log(`starting at ${base.luminance()}, aiming for ${target}`)
    for (let i = 0; i < 8; i++) {
        if (c.luminance() > target) {
            c = c.darken(Math.abs(c.luminance() - target) / 2)
        } else {
            c = c.lighten(Math.abs(c.luminance() - target) / 2)
        }
        console.log(`now at ${c.luminance()}`)
    }
    console.log(`returning ${c.luminance()}`)
    return c
}

function rotateClamp(value: number, max = 360) {
    if (value > max) return value - max
    if (value < 0) return value + max
    return value
}

function interpolate(src: Array<number>, target: number) {
    let result = []
    const step = (src.length - 1) / (target - 1)
    let pointer = 0

    for(let i = 0; i < target - 1; i++) {
        result.push(src[Math.floor(pointer)] + ((pointer % 1)*(src[Math.ceil(pointer)] - src[Math.floor(pointer)])))
        pointer += step
    }

    result.push(src[src.length - 1])
    return result
}

@Component({
    components: {Fieldset, Action}
})
export default class SwatchTweaker extends Vue {
    @Prop() value!: Array<string>

    /**
     * Standard hue adjustments
     */

    twistHueCW(multi: number = 1) {
        this.mutate((color, i, total) => {
            return color.rotate(-(i - (total / 2) * multi))
        })
    }

    twistHueCCW(multi: number = 1) {
        this.mutate((color, i, total) => {
            return color.rotate((i - (total / 2) * multi))
        })
    }

    pushHueRight(multi: number = 1) {
        this.mutate((color, i, total) => {
            return color.rotate(10 * multi)
        })
    }

    pushHueLeft(multi: number = 1) {
        this.mutate((color, i, total) => {
            return color.rotate(-10 * multi)
        })
    }

    /**
     * Luminosity-locked hue adjustments
     */

    twistLockedHueCW(multi: number = 1) {
        this.mutate((color, i, total) => {
             return adjust(color.rotate(-(i - (total / 2) * multi)), color.luminance())
        })
    }

    twistLockedHueCCW(multi: number = 1) {
        this.mutate((color, i, total) => {
            return adjust(color.rotate((i - (total / 2) * multi)), color.luminance())
        })
    }

    pushLockedHueRight(multi: number = 1) {
        this.mutate((color, i, total) => {
            return adjust(color.rotate(10 * multi), color.luminance())
        })
    }

    pushLockedHueLeft(multi: number = 1) {
        this.mutate((color, i, total) => {
             return adjust(color.rotate(-10 * multi), color.luminance())
        })
    }

    /**
     * LCH
     */

    twistLchHueCW(multi: number = 1) {
        this.mutate((color, i, total) => {
            return colord({l: color.toLch().l, c: color.toLch().c, h: rotateClamp(color.toLch().h - (i - (total / 2) * multi))})
        })
    }

    twistLchHueCCW(multi: number = 1) {
        this.mutate((color, i, total) => {
            return colord({l: color.toLch().l, c: color.toLch().c, h: rotateClamp(color.toLch().h + (i - (total / 2) * multi))})
        })
    }

    pushLchHueRight(multi: number = 1) {
        this.mutate((color, i, total) => {
            return colord({l: color.toLch().l, c: color.toLch().c, h:rotateClamp(color.toLch().h + (10 * multi)) })
        })
    }

    pushLchHueLeft(multi: number = 1) {
        this.mutate((color, i, total) => {
            return colord({l: color.toLch().l, c: color.toLch().c, h: rotateClamp(color.toLch().h - (10 * multi))})
        })
    }


    /**
     * Standardizing
     */

    standardizeToTailwind() {
        const tgt = [0.95, 0.81, 0.66, 0.5, 0.36, 0.21, 0.14, 0.09, 0.06]
        const normalized = interpolate(tgt, Object.keys(this.value).length)
        this.mutate((color, i, total) => {
            return adjust(color, normalized[i])
        })
    }

    standardizeToTailwindHighContrast() {
        const tgt = [0.95, 0.88, 0.8, 0.66, 0.42, 0.21, 0.09, 0.04, 0.01]
        const normalized = interpolate(tgt, Object.keys(this.value).length)
        this.mutate((color, i, total) => {
            return adjust(color, normalized[i])
        })
    }

    standardizeToMaterial() {
        const tgt = [0.87, 0.7, 0.55, 0.42, 0.35, 0.29, 0.24, 0.18, 0.14, 0.08]
        const normalized = interpolate(tgt, Object.keys(this.value).length)
        this.mutate((color, i, total) => {
            return adjust(color, normalized[i])
        })
    }

    /**
     * Standardizing with LCH
     */

    standardizeToLchTailwind() {
        const tgt = [0.98, 0.918, 0.849, 0.766, 0.66, 0.53, 0.453, 0.364, 0.286]
        const normalized = interpolate(tgt, Object.keys(this.value).length)
        this.mutate((color, i, total) => {
            return colord({l: normalized[i] * 100, c: color.toLch().c, h: color.toLch().h})
        })
    }

    standardizeToLchTailwindHighContrast() {
        const tgt = [0.975, 0.948, 0.897, 0.825, 0.721, 0.626, 0.473, 0.364, 0.240]
        const normalized = interpolate(tgt, Object.keys(this.value).length)
        this.mutate((color, i, total) => {
            return colord({l: normalized[i] * 100, c: color.toLch().c, h: color.toLch().h})
        })
    }

    standardizeToLchMaterial() {
        const tgt = [0.947, 0.87, 0.787, 0.706, 0.651, 0.61, 0.548, 0.484, 0.445, 0.347]
        const normalized = interpolate(tgt, Object.keys(this.value).length)
        this.mutate((color, i, total) => {
            return colord({l: normalized[i] * 100, c: color.toLch().c, h: color.toLch().h})
        })
    }




    mutate(action: (color: Colord, index: number, total: number) => Colord) {

        let res = Object.entries(this.value)
            .map(([key, val]) => [key, colord(val)])
            .map(([key, val], index, all) => [key, action(colord(val), index, all.length)])

        this.$emit('input', Object.fromEntries(res))
    }
}
